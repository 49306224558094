<template>
    <div class="other_user_streams_layout">
        <v-row class="mt-13 mb-16 top-text" no-gutters align="baseline">
            <v-col cols="1" class="ml-2 back-icon">
                <router-link
                    :to="{
                        name: 'otherUser.profile.viewSlug',
                        params: { slug: otherUser.slug },
                    }"
                    class="linked"
                >
                    <v-icon size="33">mdi-chevron-left</v-icon>
                </router-link>
            </v-col>
            <v-col class="ml-5">
                {{ otherUser.name + $t("pages.profile.stream.header") }}
            </v-col>
        </v-row>

        <div
            style="
                display: flex;
                flex-direction: column;
                justify-content: center;
            "
        >
            <div
                v-if="!!otherUser.stream && !hasStreamTicket"
                class="px-8 pb-8"
            >
                <div class="text-center text-h4">
                    {{ $t("pages.profile.stream.title") }}
                </div>
                <div class="text-center mt-2 mb-10">
                    {{
                        formatDate(
                            convertUTCDatetimeToLocal(
                                otherUser.stream.starts_at
                            )
                        )
                    }}
                </div>

                <div class="other_user_streams_layout__unlock-heart">
                    <icon-heart-lock />
                    <div class="other_user_streams_layout__unlock-price">
                        {{
                            otherUser.stream.is_free_for_subscribers &&
                            otherUser.isSubscribed
                                ? 0
                                : otherUser.stream.price
                        }}{{ currency }}
                    </div>
                </div>
                <div class="other_user_streams_layout__unlock-button">
                    <gradient-button
                        @click="purchaseTicket()"
                        class="mt-4 enable-events"
                        >{{
                            $t("pages.profile.stream.purchaseTicket")
                        }}</gradient-button
                    >
                </div>
                <div class="text-center grey--text">
                    {{ otherUser.stream.stream_tickets.length }}/{{
                        otherUser.stream.viewers_limit
                    }}
                </div>
            </div>

            <div v-if="!!otherUser.stream && hasStreamTicket" class="px-8 pb-8">
                <div class="text-center text-h4">
                    {{ $t("pages.profile.stream.title") }}
                </div>
                <div class="text-center mt-2 mb-10">
                    {{
                        formatDate(
                            convertUTCDatetimeToLocal(
                                otherUser.stream.starts_at
                            )
                        )
                    }}
                </div>

                <div style="display: flex; justify-content: center">
                    <gradient-button
                        class="mx-4 mb-4 button-story-unlock enable-events"
                        :class="
                            !isAvailableToGoLive
                                ? 'gradient-button--disabled'
                                : ''
                        "
                        @click="handleStartWatching()"
                    >
                        {{ $t("pages.profile.stream.startWatching") }}
                    </gradient-button>
                </div>

                <div class="text-center grey--text">
                    {{ otherUser.stream.stream_tickets.length }}/{{
                        otherUser.stream.viewers_limit
                    }}
                </div>
            </div>
        </div>

        <div class="grey--text caption text-center">
            {{ $t("pages.user.setting.creator.streams.rules.title")
            }}<a href="/stream-rules" target="_blank">{{
                $t("pages.user.setting.creator.streams.rules.name")
            }}</a
            >.
        </div>

        <template v-if="otherUser && otherUser.streams">
            <hr style="opacity: 0.25" class="mx-6 my-16" />

            <!-- rating -->
            <div class="text-center text-h4">
                {{ $t("pages.profile.streams.rating.title") }}
            </div>

            <div
                class="rating mt-6"
                :class="
                    averageStreamerRating < 50
                        ? 'rating--red'
                        : averageStreamerRating < 70
                        ? 'rating--orange'
                        : averageStreamerRating < 90
                        ? 'rating--yellow'
                        : 'rating--green'
                "
            >
                <svg>
                    <circle cx="105" cy="105" r="90"></circle>
                    <circle
                        cx="105"
                        cy="105"
                        r="90"
                        :style="`--percent: ${averageStreamerRating}`"
                    ></circle>
                </svg>
                <div class="title text-h2">
                    <div>
                        {{
                            averageStreamerRating < 50
                                ? "😞"
                                : averageStreamerRating < 70
                                ? "😐"
                                : averageStreamerRating < 90
                                ? "😃"
                                : "😍"
                        }}
                    </div>
                </div>
            </div>

            <div
                class="text-center justify-center mt-4 pb-16"
                style="display: flex"
            >
                <div class="mr-4">
                    <div class="text-h5">{{ otherUser.streams.length }}</div>
                    <div style="opacity: 0.5">
                        {{ $t("pages.profile.streams.rating.stats.streams") }}
                    </div>
                </div>

                <div class="ml-4">
                    <div class="text-h5">
                        {{ averageStreamsDuration }}
                        {{ $t("pages.profile.streams.rating.stats.min") }}
                    </div>
                    <div style="opacity: 0.5">
                        {{
                            $t(
                                "pages.profile.streams.rating.stats.averageDuration"
                            )
                        }}
                    </div>
                </div>
            </div>
        </template>

        <!-- insufficient funds error dialog -->
        <InsufficientFundsDialog
            v-if="showInsufficientFundsDialog"
            @close="showInsufficientFundsDialog = false"
        />

        <!-- stream not started yet dialog -->
        <stream-not-started-yet
            v-if="showStreamNotStartedYetDialog"
            :date="
                formatDate(
                    convertUTCDatetimeToLocal(otherUser.stream.starts_at)
                )
            "
            :difference="streamStartsAtDifferenceMessage"
            @close="showStreamNotStartedYetDialog = false"
        />
    </div>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import GradientButton from "@/components/app/button/GradientButton";
import TextField from "@/components/app/form/TextField.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import IconDone from "@/assets/images/icons/done.svg?inline";
import { convertUTCDatetimeToLocal, formatDate } from "@/helpers/functions";
import IconHeartLock from "@/assets/images/icons/heart-lock.svg?inline";
import { CURRENCY_SYMBOL } from "@/configs/constants";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog.vue";
import StreamNotStartedYet from "@/components/app/dialogs/StreamNotStartedYetDialog.vue";

export default {
    name: "OtherUserStreams",
    components: {
        StreamNotStartedYet,
        InsufficientFundsDialog,
        TextField,
        GradientButton,
        SettingLayout,
        IconHeartLock,
        IconDone,
    },
    data() {
        return {
            currency: CURRENCY_SYMBOL,
            showInsufficientFundsDialog: false,
            showStreamNotStartedYetDialog: false,
        };
    },
    computed: {
        ...mapState({
            profileImage: (state) => state.otherUser.user.profileImage,
            otherUser: (state) => state.otherUser.user,
            user: (state) => state.user.user.data,
        }),
        hasStreamTicket() {
            return (
                !!this.user.streamTickets.find(
                    (userStreamTicket) =>
                        userStreamTicket.stream_id === this.otherUser.stream.id
                ) || this.otherUser.stream.price === 0
                // ||
                // (this.otherUser.stream.is_free_for_subscribers &&
                //     this.otherUser.isSubscribed)
            );
        },
        isAvailableToGoLive() {
            if (!this.otherUser || !this.otherUser.stream) return false;
            let starts_at = convertUTCDatetimeToLocal(
                this.otherUser.stream.starts_at
            );
            const diff = starts_at.getTime() - new Date().getTime();
            return (
                ((diff <= 10 * 60 * 1000 && diff >= 0) || diff < 0) &&
                this.otherUser.stream.is_live
            );
        },
        streamStartsAtDifferenceMessage() {
            let starts_at = convertUTCDatetimeToLocal(
                this.otherUser.stream.starts_at
            );
            const diff = starts_at.getTime() - new Date().getTime();

            const days = diff / (1000 * 60 * 60 * 24);
            const hours = diff / (1000 * 60 * 60);
            const minutes = diff / (1000 * 60);

            if (days >= 1) {
                return `${Math.floor(days)} ${this.$i18n.t("dialogs.days")}`;
            } else if (hours >= 1) {
                return `${Math.floor(hours)} ${this.$i18n.t("dialogs.hours")}`;
            } else if (minutes >= 0) {
                return `${Math.floor(minutes)} ${this.$i18n.t(
                    "dialogs.minutes"
                )}`;
            }
            return null;
        },
        averageStreamerRating() {
            if (!this.otherUser.streamsRatings) return 0;
            const sum = this.otherUser.streamsRatings.reduce(
                (accumulator, currentObject) =>
                    accumulator + currentObject.rate,
                0
            );
            return sum / this.otherUser.streamsRatings.length;
        },
        averageStreamsDuration() {
            if (!this.otherUser.streams) return 0;
            const sum = this.otherUser.streams.reduce(
                (accumulator, currentObject) =>
                    accumulator + currentObject.minutes_live,
                0
            );
            return Math.round(sum / this.otherUser.streams.length);
        },
    },
    methods: {
        convertUTCDatetimeToLocal,
        ...mapMutations(["showSnackBar"]),
        formatDate,
        ...mapActions(["setOtherUserBySlug"]),
        purchaseTicket() {
            axios
                .post(`/api/stream/${this.otherUser.stream.id}/purchase-ticket`)
                .then((response) => {
                    this.user.streamTickets.push(response.data);
                    this.setOtherUserBySlug(this.$route.params.slug);
                })
                .catch((error) => {
                    if (error.response.data.message === "not enough money") {
                        this.showInsufficientFundsDialog = true;
                        return;
                    }

                    this.showSnackBar(error.response.data.message);
                });
        },
        handleStartWatching() {
            if (!this.isAvailableToGoLive) {
                this.showStreamNotStartedYetDialog = true;
                return;
            }

            this.$router.push({
                name: "stream",
                params: {
                    slug: this.otherUser.slug,
                    token: this.otherUser.stream.token,
                },
            });
        },
    },
    async mounted() {
        await this.setOtherUserBySlug(this.$route.params.slug).catch(() => {
            this.$router.push({
                name: "otherUser.profile.viewSlug",
                params: { slug: this.otherUser.slug },
            });
        });
        if (!this.otherUser.stream) {
            return await this.$router.push({
                name: "otherUser.profile.viewSlug",
                params: { slug: this.otherUser.slug },
            });
        }

        /*
         * connect to stream signals channel
         */
        this.$echo.connector.connect();
        const channel = this.$echo.channel(
            `stream-signalling.${this.otherUser.stream.id}`
        );

        channel.listen("StreamSignalEvent", async (event) => {
            if (!!event.is_live) {
                this.otherUser.stream.is_live = event.is_live;
            }
        });
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.other_user_streams_layout {
    background: black;
    width: 100%;
    height: 100vh;
}

.top-text {
    font-size: 1.7em;
    font-weight: 900;
}

.back-icon {
    font-size: 1.1em;
}

.other_user_streams_layout__unlock {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &.is-own {
        justify-content: flex-end;
    }

    &-image {
        border-radius: 24px;
        height: 270px;
        overflow: hidden;
        position: relative;
        display: flex;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &-lock {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &-heart {
        width: 70px;
        height: 94px;
        position: relative;
        margin: 64px auto;
        transform: scale(1.5);

        & > svg {
            width: 70px !important;
            height: 94px !important;
        }
    }

    &-price {
        position: absolute;
        top: 44px;
        width: 100%;
        text-align: center;
        color: #fe4811;
        font-size: 1.2em;
    }

    &-button {
        display: flex;
        justify-content: center;
    }
}

.gradient-button--disabled {
    background: rgba(237, 237, 237, 0.1) !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

/*
 * rating
 */
.rating {
    position: relative;
    display: flex;
    justify-content: center;

    svg {
        position: relative;
        width: 210px;
        height: 210px;
        transform: rotate(-90deg);

        circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 20;
            stroke-linecap: round;

            &:last-of-type {
                stroke-dasharray: 560px;
                stroke-dashoffset: calc(560px - (560px * var(--percent)) / 100);
            }
        }
    }

    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.rating--red {
    svg {
        circle {
            stroke: rgba(253, 18, 18, 0.25);
            &:last-of-type {
                stroke: #fd1212;
            }
        }
    }
}
.rating--orange {
    svg {
        circle {
            stroke: rgba(253, 83, 18, 0.25);
            &:last-of-type {
                stroke: #fd5312;
            }
        }
    }
}
.rating--yellow {
    svg {
        circle {
            stroke: rgba(253, 214, 18, 0.25);
            &:last-of-type {
                stroke: #fde912;
            }
        }
    }
}
.rating--green {
    svg {
        circle {
            stroke: rgba(18, 253, 38, 0.25);
            &:last-of-type {
                stroke: #60fd12;
            }
        }
    }
}
</style>
